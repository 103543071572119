export const baseUrl = String(process.env.REACT_APP_API_BASE_URL);
export const adminBaseUrl = String(process.env.REACT_APP_ADMIN_BASE_URL);
export const tribesConfig = {
  addressLine1: String(process.env.REACT_APP_TRIBE_ADDRESS_LINE_1),
  addressLine2: String(process.env.REACT_APP_TRIBE_ADDRESS_LINE_2),
  email: String(process.env.REACT_APP_TRIBE_EMAIL),
  legalEmail: String(process.env.REACT_APP_TRIBE_LEGAL_EMAIL),
  mailingAddressLine1: String(
    process.env.REACT_APP_TRIBE_MAILING_ADDRESS_LINE_1,
  ),
  mailingAddressLine2: String(
    process.env.REACT_APP_TRIBE_MAILING_ADDRESS_LINE_2,
  ),
  name: String(process.env.REACT_APP_TRIBE_NAME),
  nameCaps: String(process.env.REACT_APP_TRIBE_NAME).toUpperCase(),
  phone: String(process.env.REACT_APP_TRIBE_PHONE),
  website: String(process.env.REACT_APP_TRIBE_WEBSITE),
  tribalLands: String(process.env.REACT_APP_TRIBE_TRIBAL_LANDS),
  tribalLandsDistrict: String(
    process.env.REACT_APP_TRIBE_TRIBAL_LANDS_DISTRICT,
  ),
  state: String(process.env.REACT_APP_TRIBE_STATE),
  regulatoryLicenseNumber: String(
    process.env.REACT_APP_REGULATORY_LICENSE_NUMBER,
  ),
  regulatoryInstitution: String(process.env.REACT_APP_REGULATORY_INSTITUTION),
};
export const baseUrlPayplan = String(process.env.REACT_APP_API_PAYPLAN_URL);
